<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-clipboard-flow-outline"
      title="Change Orders"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="agentSpecificChangeOrders"
        disable-pagination
        >

        <template v-slot:[`item.change_order`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="view_change_order(item)"
            >
               mdi-pencil
            </v-icon>

        <v-dialog
            v-model="dialog"
            max-width="50%"
            :retain-focus="false"
          >
          <v-form
          ref="form"
          >
            <v-card>
                <v-card-title>
                  <span class="card-title font-weight-light">Change Order</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                   
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="selected_change_order.HomeOwnerName"
                            label="Homeowner Name"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="selected_change_order.Title"
                            label="Change Title"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-textarea
                            v-model="selected_change_order.HomeOwnerComment"
                            label="Homeowner comments"
                            :rules="commentRules"
                            disabled
                          ></v-textarea>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-textarea
                            v-model="selected_change_order.StaffComment"
                            label="Additional Notes From Boulder staff (Attached to Email)"
                            :rules="commentRules"
                          ></v-textarea>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                        <v-autocomplete
                          :items="vendors"
                          label="Vendor"
                          item-text="Email"
                          item-value="VendorId"
                          menu-props="auto"
                          class="px-3 py-3"
                          :rules="vendorRules"
                          v-model="selected_change_order.VendorId"
                        ></v-autocomplete>

                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="selected_change_order.Cost"
                            label="Cost"
                            type="number"
                            :counter="4"
                            :rules="costRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!valid"
                  >
                    Approve
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!valid"
                  >
                    Decline
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
        </template>
        
      </v-data-table>
    </base-material-card>
  </v-container>
</template>


<script>
import axios from 'axios';

export default {
    name: 'AgentChangeOrders',
    data () {
      return {
        dialog: false,
        valid: false,
        permissions:localStorage.getItem('permissions'),
        staff_email: 'steve@skelleymarketing.com',
        boulder_email : 'steve@skelleymarketing.com',
        commentRules: [
          v => !!v || 'Comments are required',
          ],
        costRules: [
          v => !!v || 'Cost is required',
          v => (v<=9999) || 'Max 4 characters.',
          ],
        vendorRules: [
          v => !!v || 'Vendor is required'
          ],
        headers: [
            {
            text: 'Homeowner Name',
            sortable: false,
            class:"primary--text",
            value: 'HomeOwnerName',
            },
            { 
            text: 'Change Order Type', 
            value: 'Title', 
            sortable: false,
            class:"primary--text",
            },
            { 
            text: 'Status', 
            value: 'StatusId', 
            align: 'end',
            sortable: false,
            class:"primary--text",
            },
            { 
            text: 'Approve/Decline', 
            value: 'change_order', 
            align: 'end',
            sortable: false,
            class:"primary--text",
            }
        ],
        user: [],
        agentsHomeOwners: [],
        change_orders: [],
        selected_change_order: {
            HomeOwnerId:'',
            HomeOwnerName: '',
            HomeownerEmail:'',
            VendorEmail:'',
            VendorId:'',
            OrderId:'',
            Title: '',
            HomeOwnerComment: '',
            StaffComment: '',
            Cost:''
        },
        default_change_order: {
            HomeOwnerId:'',
            HomeOwnerName: '',
            HomeownerEmail:'',
            VendorEmail:'',
            VendorId:'',
            OrderId:'',
            Title: '',
            HomeOwnerComment: '',
            StaffComment: '',
            Cost:''
        },
        vendors: [],
        filteredAgentHomeOwners: [],
        agentSpecificChangeOrders: [],
      }
    },

    created () {
      this.getAgentHomeOwners()
      this.initializeChangeOrders()
      this.initializeVendors()
    },

    methods: {
      view_change_order (change_order) {
        this.selected_change_order = Object.assign({}, change_order)
        this.dialog = true
      },
      getAgentHomeOwners() {
            axios
            .get(process.env.VUE_APP_GET_HOMEOWNER_AGENT_URL,
            {
            params: {
                email_address: this.$auth.user.email || localStorage.getItem('agentEmail')
            }
            })
        .then(response => {
          const {data} = response
          this.user = data
          this.user.forEach(element => this.filteredAgentHomeOwners.push(element.PrimaryName))
        })
        .catch(error => {
          console.log(error)
        })
        },
        
      close () {
        this.dialog = false
      },

      initializeChangeOrders () {
        
        axios
        .get(process.env.VUE_APP_GET_CHANGEORDERS_URL)
        .then(response => {
          this.change_orders = response.data
          this.change_orders.forEach((change_order) => {
            if(this.filteredAgentHomeOwners.includes(change_order.HomeOwnerName)){
              const {StatusId} = change_order
              change_order.StatusId = this.change_order_status_mapper(StatusId)
              this.agentSpecificChangeOrders.push(change_order)
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
      },
      initializeVendors () {
        axios
        .get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL, {})
        .then(response => {
            const { data } = response
            this.vendors = data
        })
        .catch(error => {
            console.log(error)
        })
      },
      pending (){
        var body = {
          "vendor_id":this.selected_change_order.VendorId || 'TBD',
          "owner_comment":this.selected_change_order.HomeOwnerComment || 'No comment provided',
          "staff_comment":this.selected_change_order.StaffComment || 'No comment provided',
          "cost":this.selected_change_order.Cost || 'TBD',
          "status": 'PENDING',
          "order_id":this.selected_change_order.OrderId,
          ...this.selected_change_order
        }
        this.close()
        this.sendEmail([this.selected_change_order.HomeownerEmail,this.staff_email],this.staff_email,"change_order_homeowner_pending_notification",body,this.selected_change_order.Title)
      },
      sendEmail(to,from,template,content,title){

        var email_body ={
          "to": to,
          "from": from,
          "template_name": template,
          "template_data": {
            "content": content,
            "title" : title
          }
        }

        axios
        .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })

      },
      change_order_status_mapper(StatusID){
        if(StatusID === 3){
          return 'Declined'
        }
        else if(StatusID === 2){
          return 'Approved'
        }
        if(StatusID){
          return 'Pending'
        }
      }
  },

}
</script>

<style scoped>

</style>